@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;500;600&display=swap');

@import '../scss/colors/colors.scss';

body {
  background: #fafafa;
  font-family: 'Montserrat';
  overflow: hidden;
  padding-top: 15px;
  padding-left: 15px;
}

.flex-start {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-start-center {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

:root {
  --transition: 0.2s all;
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.screen {
  width: calc(100% - 233px);
  padding-left: 30px;
  padding-right: 30px;
  &-section {
    &-container {
      border-radius: 10px;
      border-top-left-radius: 0px;
      padding: 30px;
      box-shadow: 0px 5px 10px #0812261a;
      width: 100%;
      background: white;
    }
  }
}

.app {
  align-items: flex-start;
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.capitalize {
  text-transform: capitalize;
}

.one-line {
  white-space: nowrap;
}

.search-input {
  align-items: flex-start;
  position: relative;
  z-index: 10;
  svg {
    color: #b9c0cd;
  }
  &-not-found {
    position: absolute;
    top: calc(100% + 10px);
    font-weight: 500;
  }

  &-title {
    font-size: 21px;
    font-weight: 600;
    margin-right: 20px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  &-box {
    box-shadow: 0px 5px 10px #a3abba33;
    border-radius: 5px;

    background: white;
    position: relative;

    input {
      width: calc(100% - 40px);
      border: 0px;
      height: 46px;
      color: $input-color;
      text-indent: 15px;
      font-size: 15px;
      font-weight: 500;
      z-index: 10;
      position: relative;
    }

    &-results {
      z-index: 10;
      width: 100%;
      max-height: 200px;
      overflow-y: auto;
      background: white;
      position: absolute;
      top: calc(100% - 2px);
      box-shadow: 0px 5px 10px #a3abba33;

      li {
        height: 35px;
        align-items: center;
        padding: 0px 20px;
        cursor: pointer;
        transition: 0.1s;

        p {
          font-size: 15px;
          font-weight: 500;
          line-height: normal;
        }

        &:hover {
          background: $list-hover-color;
        }
      }
    }

    &-btn {
      position: absolute;
      width: 15px;
      z-index: 100;
      height: 15px;
      right: 20px;
      top: 50%;
      transform: translate(0, -50%);

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

input:focus::placeholder {
  color: transparent;
  transition: 0.2s all;
}

input::placeholder {
  transition: 0.2s all;
}

.chart-tootlip {
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  pointer-events: none;
  // transition: 0.1s all;
  color: white;
  border-radius: 5px;
  z-index: 10;
  p {
    white-space: nowrap;
    font-family: 'Montserrat';
    padding-left: 10px;
    padding-right: 10px;
    line-height: normal;
  }
  &-title {
    margin-top: 5px;
    font-weight: 500;
  }
}

.rewards-list {
  .MuiTableCell-head {
    padding-bottom: 20px;
  }
}

@media (max-width: 576px) {
  body {
    padding: 0;
    overflow: initial;
    .screen {
      width: 100%;
      padding: 0;
    }
  }
}

@media (max-width: 800px) {
  .search {
    p {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      font-size: 15px;
    }

    &-input {
      &-box {
        &-results {
          max-height: calc(100vh - 170px);
        }
      }
    }
  }
}
