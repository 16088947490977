.overview {
    padding-top: 20px;
    .screen-section {
        margin-top: 30px;
    }
    .screen-section-container {
        height: 500px;
    }
    &-flex {
        width: 100%;
        height: 100%;
        position: relative;
    }
    .no-data {
        position: absolute;
        left: 50%;
        transform: translate(-50%);
    }
}

.overview-chart {
    height: 100%;
    width: calc(100% - 350px);

    header {
        margin-bottom: 50px;
    }

    .bar-chart {
        height: 330px;
    }

    h4 {
        font-weight: 600;
    }
}

.mobile-overview-chart {
    width: 100%;
    height: auto;

    &-chart {
        position: relative;
        width: 70vw;
        height: 70vw;
        margin: auto;
        min-width: 260px;
        min-height: 260px;
    }
    &-selected {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 70%;
        cursor: pointer;
        p {
            font-size: 14px;
            margin-bottom: 5px;
            font-weight: 500;
            width: 100%;
            text-align: center;
        }
        h5 {
            font-size: 16px;
        }
    }
    &-title {
        margin-bottom: 35px;
        &-name {
            width: 50%;
            font-size: 16px;
            text-transform: uppercase;
            font-weight: 600;
            padding-right: 15px;
            text-align: right;
            position: relative;
            &:after {
                position: absolute;
                right: 0px;
                width: 1px;
                height: 15px;
                top: 50%;
                transform: translate(0, -50%);
                background: rgba(0, 0, 0, 0.1);
                content: '';
            }
        }
    }
}

@media (max-width: 576px) {
    .overview {
        background: white;
        position: relative;
        height: 100%;
      
        &::after {
            position: fixed;
            bottom: -20px;
            left: 0px;
            height: 20px;
            width: 100%;
            content: '';
            -webkit-box-shadow: 0px -17px 65px 56px rgba(255, 255, 255, 1);
            -moz-box-shadow: 0px -17px 65px 56px rgba(255, 255, 255, 1);
            box-shadow: 0px -17px 65px 56px rgba(255, 255, 255, 1);
        }
      
      
        .screen-section {
            margin-top: 0;
        
            &-container {
                height: auto;
                .loader{
                  height: calc(100vh - 300px);
                }
            }
        }
    }

    .overview {
        &-flex {
            align-items: center;
        }
    }
    .overview-chart {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .mobile-overview-chart {
        margin-bottom: 40px;
    }
    .days-selector-selected {
        padding-left: 15px;
    }
}
