@import '../../scss/colors/colors.scss';
.balance-section {
  height: 100%;
  position: relative;
  padding-left: 40px;
  padding-right: 40px;
  &:nth-child(1) {
    padding-left: 0px;
    &::after {
      display: none;
    }
  }

  &::after {
    position: absolute;
    content: '';
    left: 0px;
    width: 1px;
    height: 75px;
    background: #d9d9d9;
  }
  img {
    width: 15px;
    height: 15px;
    margin-top: auto;
    object-fit: contain;
    margin-bottom: 8px;
    margin-left: 5px;
  }
  &-bold {
    font-size: 23px;
    font-weight: 600;
    font-family: 'Nunito Sans';
  }
  &-small {
    font-size: 14px;
    color: black;
    margin-top: 10px;
    width: 100%;
  }

  h4 {
    margin-bottom: 15px;
    font-size: 18px;
    font-family: 'Nunito Sans';
  }

  h5 {
    font-size: 18px;
    font-weight: 600;
    color: $dark-font-color;
    margin-bottom: 10px;
  }
  .loader {
    width: 100px;
    height: 20px;
  }
}

@media (max-width: 576px) {
  .balance-section {
    padding: 15px;
    margin-right: 3px;
    margin-bottom: 4px;
    text-align: center;
    background: #f8f8f8;
    height: initial;
    width: calc(50% - 3px);
    height: 90px;
    .one-line {
      font-size: 15px;
    }
    .balance-section-bold {
      font-size: 20px;
      text-align: center;
    }
    &:after {
      content: none;
    }
    &:nth-child(1) {
      padding-left: 15px;
    }
    &:nth-child(even) {
      margin-right: 0;
    }
    section{
      margin-bottom: 10px;
      h5{
        margin: 0px;
        font-size: 12px;
        white-space: normal;
        text-align: start;
        width: 100px;
      }
      img{
        margin: 0px;
        width: 25px;
        height: auto;
        margin-right: 10px;
      }
  }

  }
}
