@import 'scss/colors/colors.scss';

.delegators-stake-balance {
  margin: auto;
  height: 100px;
  border-bottom: 1px solid #d9d9d9;
  padding-top: 0px;
  padding-left: 20px;
  &-section {
    position: relative;
    height: 100%;
    padding-left: 50px;
    &::after {
      position: absolute;
      content: '';
      left: 0px;
      width: 1px;
      height: 86px;
      background: #d9d9d9;
    }
    h4 {
      color: $balance-title;
      font-family: 'Nunito Sans';
      font-size: 18px;
      margin-bottom: 15px;
    }
  }
  &-address {
    color: $light-gray;
    font-size: 12px;
    margin-top: 5px;
  }
  &-to {
    color: black;
    font-family: 'Nunito Sans';
    font-weight: 600;
    font-size: 18px;
  }
}

@media (max-width: 576px) {
  .delegators-stake-balance {
    height: initial;
    padding: 0;
    margin: 0;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: none;
    .delegators-stake-balance-section {
      padding: 15px;
      margin-right: 3px;
      margin-bottom: 4px;
      text-align: center;
      background: #f8f8f8;
      height: initial;
      width: calc(50% - 3px);
      height: 90px;
      h4 {
        font-size: 16px;
        margin-bottom: 5px;
      }
      a {
        .delegators-stake-balance-to {
          font-size: 12px;
        }
      }
      &:after {
        content: none;
      }
    }
  }
}
