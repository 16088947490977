@import 'scss/colors/colors.scss';

.guardian-stake {
  height: 100%;
  min-height: 420px;
  justify-content: flex-start;
  &-flex {
    height: calc(100% - 100px);
    width: 100%;
    padding-top: 30px;
  }
}

@media (max-width: 576px) {
  .guardian-stake-flex {
    padding-top: 0;
    margin-top: 18px;
    flex-direction: column;
  }
}
