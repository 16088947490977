.guardian-top {
  background: white;
  height: 154px;
  box-shadow: 0px 5px 10px #0812261a;
  padding: 30px 20px 20px 20px;
  border-radius: 10px;
  &-mobile-toggle {
    display: none;
    width: 20px;
    height: 10px;
    margin-top: 20px;
    figure {
      height: 2px;
      background: black;
      &:nth-child(1) {
        width: 100%;
      }
      &:nth-child(2) {
        width: 50%;
        margin-top: auto;
      }
    }
  }
}

@media (max-width: 576px) {
  .guardian-top {
    height: auto;
    padding: 20px;
    box-shadow: none;
    .component-wrapper {
      flex-direction: column;
      border-radius: 10px;
      box-shadow: 0px 0px 13px -1px rgba(0, 0, 0, 0.5);
      padding: 18px;
    }
    &-mobile-toggle {
      display: flex;
      align-items: center;

      flex-direction: column;
    }
  }
}
