@media (max-width: 567px) {
  .delegator-search {
    width: 100%;
    .search-input-title {
      text-align: center;
      font-size: 15px;
      margin-bottom: 14px;
      margin-right: 0;
    }
    section.search-input-box {
      width: 100%;
      border-color: #d8d8d8;
      &-button {
        input {
          color: #aaaaaa;
          font-size: 14px;
        }
      }
    }
  }
}
