

.delegators-rewards-actions{
height: calc(100% - 107px);    
padding-top: 30px;

    .loader-list {
        &-element {
            div {
                width: 200px;
                padding-right: 40px;
                .loader {
                    height: 20px;
                    width: 100px;
                }
            }
        }
    }
}

@media (max-width: 576px) {
    .delegators-rewards-actions{
        padding-top: 0px;
    }
  }
  