@import '../../scss/colors/colors.scss';

.navigation {
  width: 233px;
  height: calc(100vh - 30px);
  background: $navigation-menu;
  position: relative;
  box-shadow: 0px 5px 10px #00000029;
  border-radius: 13px;
  overflow: hidden;
  align-items: center;
  padding-top: 30px;
  color: white;

  .crypto-com-widget {
     width: 100%;
  }

  &-logo {
    width: 72px;
    height: 70px;
    object-fit: cover;
    margin-bottom: 16px;
  }
  h4,
  h5 {
    text-transform: uppercase;
    font-size: 13px;
  }
  h4 {
    margin-bottom: 5px;
    font-weight: 600;
  }

  &-list {
   
    width: 100%;
    &-item {
      height: 90px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      &-link {
        width: 100%;
        align-items: center;
        justify-content: center;
        height: 100%;

        img {
          width: 25px;
          height: 25px;
          object-fit: contain;
          margin-bottom: 10px;
        }
        p {
          font-size: 16px;
          font-weight: 500;
          color: white;
          border: 0px;
        }
      }
      &-selected {
        p {
          font-weight: 600;
          color: #03fcf5;
        }
      }
    }
  }
  &-languages {
    margin-top: auto;
    margin-bottom: 20px;
    position: relative;
    z-index: 10;
    &-logo {
      margin: auto;
      width: 30px;
      margin-bottom: 20px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    ul {
      li {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
  &.mobile {
  }
}

@media (max-width: 576px) {
  .navigation-mobile {
    width: 100%;
    background: #1c1c1c;
    height: 77px;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0px;
    z-index: 99;
    .nav-header-mobile {
      padding: 0 25px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      .nav-header-navigation-logo {
        width: 36px;
        height: 36px;
        margin-bottom: 0;
        margin-left: 55px;
        margin-right: 5px;
        &.back-left {
          margin-left: 0;
        }
      }
      .titles {
        color: #fff;
        font-size: 10px;
        .title {
          font-weight: 600;
        }
      }
      .x-btn {
        color: #3d3d3d;
        margin-left: 85px;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
  .side-nav-mobile {
    position: fixed;
    top: 77px;
    left: 0;
    padding: 0;
    z-index: 100;
    width: 275px;
    h4,
    h5 {
      display: none;
    }
    .chain-selector{
      margin-left: 0px;
    }

    .navigation {
      border-radius: 0;
      border-bottom-right-radius: 13px;
      height: calc(100vh - 77px);
      width: 100%;
      &-list {
        margin: 0;
        padding-left: 25px;
        .navigation-list-item {
          height: 65px;
          .navigation-list-item-link {
            align-items: initial;
            p {
              font-size: 15px;
              line-height: initial;
              padding: 0;
              margin: 0;
              font-weight: 400;
            }
          }
          &:last-child {
            border-bottom: none;
          }
        }
      }
      .navigation-languages {
        margin-bottom: 50px;
      }
    }
  }
}
