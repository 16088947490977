.guardians {
  padding-top: 0px;
  .screen-section {
    margin-top: 60px;
    &-container {
      height: calc(100vh - 288px);
    }
  }
}

.line-chart-tootlip {
  transform: translate(0, -50%);
  .chart-tootlip {
    &-total {
      text-transform: capitalize;
      margin-bottom: 5px;
      margin-top: 5px;
    }
    &-data {
      p {
        &:nth-child(1) {
          margin-bottom: 3px;
          font-weight: 500;
        }
      }
      margin-top: 3px;
    }
  }
}

@media (max-width: 576px) {
  .guardians {
    background: #fff;
    .screen-section {
      margin-top: 10px;
      .screen-section-container {
        padding: 0px;
       
        height: initial;
      }
    }
  }
}
