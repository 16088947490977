@import 'scss/colors/colors.scss';

.guardian-stake-balance {
  margin-left: auto;
  margin-right: auto;
  height: 100px;
  border-bottom: 1px solid #d9d9d9;
  padding-top: 0px;
  padding-left: 20px;
  width: 100%;
}

@media (max-width: 576px) {
  .guardian-stake-balance {
    padding-left: 0;
    padding-right: 0;
    border-bottom: none;
    flex-wrap: wrap;
    height: auto;
    padding: 15px 15px 0px 15px;
  }
}
