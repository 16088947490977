.guardian-stake-chart {
  height: 100%;
  position: relative;
  width: calc(100% - 240px);

  .line-chart {
    height: calc(100% - 70px);
    position: relative;
  }

  header {
    height: 70px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    h4 {
      font-weight: 600;
      font-size: 21px;
      text-transform: uppercase;
    }
  }

  .loader-big {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
  }

  .line-chart-text {
    position: absolute;
    height: 100%;
    display: flex;
    justify-content: center;
    p {
      font-size: 13px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 40%;
    }
    small {
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 12px;
    }
    &-left {
      left: 0px;
      top: 50%;
      writing-mode: vertical-rl;
      transform: translate(0, -50%);
    }

    &-right {
      right: 0px;
      top: 50%;
      writing-mode: vertical-rl;
      transform: translate(0, -50%);
    }
  }
}

@media (max-width: 576px) {
  .guardian-stake-chart {
    padding-top: 0;
    width: 100%;
    .line-chart{
      height: 240px;
     width: calc(100% - 20px);
     margin: auto;
    }
    header {
      height: auto;
      display: block;
      text-align: center;
      margin-bottom: 20px;
      h4 {
        font-weight: 600;
        font-size: 12px;
        text-transform: uppercase;
        margin-bottom: 20px;
      }
    }
  }
}
