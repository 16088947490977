.delegator-stake-chart {
    width: 100%;
    height: calc(100% - 100px);
    position: relative;
    padding-top: 30px;
    .loader-big {
        width: 100%;
        height: calc(100% - 30px);
        position: absolute;
        top: 30px;
    }

    .line-chart {
        height: calc(100% - 70px);
        width: 100%;
    }

    header {
        height: 70px;
        text-transform: uppercase;
        align-items: flex-start;
        h4 {
            font-weight: 600;
            font-size: 21px;
        }
    }
}

@media (max-width: 576px) {
    .delegator-stake-chart {
        margin-top: 18px;
        padding-top: 0;
        width: 100%;
        margin-bottom: 40px;
        .line-chart {
            height: 240px;
        }
        header {
            height: auto;
            display: block;
            text-align: center;
            margin-bottom: 20px;
            h4 {
                font-weight: 600;
                font-size: 12px;
                text-transform: uppercase;
                margin-bottom: 20px;
            }
        }
    }
}
