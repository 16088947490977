
@import 'scss/colors/colors.scss';

.guardian-stake-legend {
    margin-left: auto;
    width: 200px;
    h4 {
        color: $light-gray;
        margin-bottom: 30px;
        font-weight: 500;
    }

    div {
        margin-bottom: 27px;
        figure {
            min-width: 14px;
            min-height: 14px;
            border-radius: 50%;
            margin-right: 10px;
        }
        p{
            color: $light-gray;
            font-size: 15px;
        }
    }
    .loader{
        width: 90%;
        height: 20px;
    }
}
@media (max-width: 576px) {
    .guardian-stake-legend{
       margin: auto;
        margin-top: 30px;
        margin-bottom: 30px;
    }
  }
  