

.guardian-rewards-actions{
    height: calc(100% - 290px);    
        .list{
            margin-top: 30px;
            height: calc(100% - 30px);
            &-item{
                min-width: 200px;
                max-width: 350px;
                width: calc(100% / 4);
            }   
            ul{
                height: calc(100% - 50px);
            }
        }
    
        .loader-list {
            margin-top: 50px;
            &-element {
                div {
                    width: calc(100% / 4);
                    padding-right: 40px;
                    .loader {
                        height: 20px;
                        width: 130px;
                    }
                }
            }
        }
    }

    @media (max-width: 576px){
        .guardian-rewards-actions {
            .list{
                margin-top: 0px;
                padding: 0px 15px;
            }
            margin-bottom: 50px;
        }
    }