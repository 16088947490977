// 
// options for editing are here node_modules/bootstrap/scss/_vhttps://getbootstrap.com/docs/4.1/getting-started/theming/#css-variablesariables.scss

$default-container-height: calc(100vh - 330px);
// Grid

$root-font: 'Montserrat';

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1500px,
  xl: 1500px
);

$grid-columns: 12;
$grid-gutter-width: 30px;
$grid-row-columns: 6;


// Color system

$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;


$blue:    #007bff;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #28a745;
$teal:    #20c997;
$cyan:    #17a2b8;


$primary:       $blue;
$secondary:     $gray-600;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-800;


