@import '../../scss/colors/colors.scss';

.delegators {
  padding-top: 55px;
  position: relative;
  .screen-section {
    margin-top: 60px;

    &-container {
      height: 100%;
      height: calc(100vh - 280px);
    }
  }

  .delegator-not-found {
    position: absolute;
    top: 160px;
  }
  .search-input-box {
    width: 500px;
  }
}

@media (max-width: 576px) {
  .delegators {
    .delegators-top {
      height: auto;
      padding: 20px;
      box-shadow: none;
      .component-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        border-radius: 10px;
        box-shadow: 0px 0px 13px -1px rgba(0, 0, 0, 0.5);
        padding: 18px 18px 25px;
      }
    }
    .screen-section {
      margin-top: 20px;
      .screen-section-container {
        height: initial;
        padding: 0px;
      }
    }
  }
}
