@import '../../scss/colors/colors.scss';
@import '../../scss/theme/foundation.scss';

.list {
  height: 100%;
  overflow-x: hidden;
  .MuiTableCell-root {
    border-bottom: 1px dashed $list-border-color;
  }
  .MuiTableCell-head {
    border: none;
  }
  p {
    color: $list-text-color;
    font-family: $root-font;
    font-weight: 500;
    font-size: 14px;
  }
  a {
    &:hover {
      text-decoration: underline;
      color: $list-text-color;
    }
  }
  &-item {
    img {
      width: 14px;
      height: 14px;
      margin-left: 5px;
      object-fit: contain;
    }
    &-tooltip {
      position: relative;
      margin-top: 10px;
      width: 90%;

      p {
        font-size: 14px;
        width: 90%;
        max-width: 130px;
      }

      figure {
        position: absolute;
        top: -10px;
        font-size: 14px;
        background: white;
        box-shadow: 0px 5px 10px #0812261a;
        padding: 10px;
        left: -10px;
        color: black;
        font-weight: 500;
        opacity: 0;
        pointer-events: none;
        transition: 0.15s all;
        text-decoration: underline !important;

        img {
          margin-left: 10px;
        }
      }

      &:hover {
        figure {
          opacity: 1;
          pointer-events: all;
        }
      }
    }
  }
  .reward-title {
    font-family: 'Montserrat';
    font-weight: bold;
    font-size: 18px;
  }
  .reward {
    .MuiTableCell-root {
      border-bottom: 1px solid $list-border-color;
    }
    &-image {
      width: 24px;
      height: auto;
      margin-right: 20px;
      object-fit: contain;
    }

    &-token {
      width: 15px;
      height: 15px;
      object-fit: contain;
      margin-left: 5px;
    }

    &-text {
      font-size: 24px;
      text-transform: capitalize;
      font-family: 'Montserrat';
    }
    &-name {
      font-weight: bold;
      font-size: 20px;
    }

    &-total {
      font-weight: bold;
    }
    .loader-text {
      width: 50%;
      height: 20px;
    }
  }
}

@media (max-width: 576px) {
  .list {
    .MuiTable-root {
      table-layout: fixed;
      overflow: hidden;
      .MuiTableHead-root {
        tr {
          .list-titles {
            font-size: 11px;
            overflow: hidden;
            white-space: normal;
          }
        }
      }
      .MuiTableBody-root {
        tr {
          td {
            p {
              font-size: 11px;
            }
            .list-item {
              font-size: 11px;
              p {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }
}
