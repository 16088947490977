.app-loader{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 99;
    h5{
        font-size: 20px;
        color: black;
    }
}